<!--
 * @Author: hwu
 * @Date: 2020-08-28 09:46:40
 * @Description: 点餐页面 -> 选择人数
 * @LastEditTime: 2024-04-12 17:20:04
-->
<template>
  <div class="app-container" :style="{ backgroundImage: 'url(' + transferImage(guideBgUrl) + ')' }">
    <img class="brand-logo" :src="transferImage(storeInfo.brandLogo, 'logo')" />
    <div class="number-box">
      <div class="number-header">
        <!-- 自取模式，没有桌位，没有预点单 -->
        <p class="number-header_text_tips">请选择就餐人数</p>
      </div>
      <div class="number-content">
        <div class="number-item" v-for="index of 9" :key="index">
          <span class="number-item_btn" :class="{ active: index === selectedNum }" @click="checkNumItem(index)">{{ index }}</span>
        </div>
      </div>
      <div class="number-more">
        <input min="1" max="999" v-if="moreNumberShow" type="number" oninput="if(value.length>3) value=value.slice(0,3)" class="number-more_input" v-model="selectedNum" placeholder="请输入就餐人数" />
        <span class="number-item_btn" :class="{ active: moreNumberShow }" @click="handleMoreNumber">{{ moreNumberShow ? '确定' : '更多人数' }}</span>
      </div>
    </div>
    <!-- loading提示 -->
    <loading-box :show="loading" />
    <!-- 下单类型选择弹窗 -->
    <dialog-box class="service-type-dialog" :show="serviceTypeDialogShow" title="请选择下单类型" rightBtnText="选好了" noPadding @onRightClick="confirmSelectServiceType">
      <div slot="body">
        <div class="service-box">
          <div class="service-item" @click="checkSeviceType(item.code)" v-for="(item, index) in serviceOptions" :key="index">
            <div class="item-row">
              <div class="service-item-left">
                <svg aria-hidden="true" v-if="item.code === serviceType">
                  <use xlink:href="#icondanxuan-xuanzhong" />
                </svg>
                <svg aria-hidden="true" v-else>
                  <use xlink:href="#icondanxuan-weixuanzhong" />
                </svg>
              </div>
              <div class="service-item-center">{{ item.name }}</div>
              <div class="service-item-right" v-if="item.code === 5">
                <input type="tel" v-model="dyOrderNum" maxlength="4" class="row_input" placeholder="输入流水号" />
              </div>
            </div>
            <div class="item-row row-desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </dialog-box>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import LoadingBox from '@/components/common/LoadingBox'
import DialogBox from '@/components/common/DialogBox'
import { getStoreInfoApi } from '@/api/store'
import { goToMenuApi } from '@/api/cart'
export default {
  components: {
    LoadingBox,
    DialogBox
  },
  data() {
    return {
      loading: true,
      storeId: '',
      tableId: '',
      tableName: '',
      entranceType: '',
      userId: '',
      storeInfo: {},
      kdsModel: '1',
      payModel: 1,
      selectedNum: '',
      togetherDialogShow: false,
      customerGroups: [],
      moreNumberShow: false,
      guideBgUrl: '294ebb372c0240f3944a0bb1f410d595',
      serviceOptions: [
        {
          code: 1,
          name: '堂食/外带点单'
        },
        {
          code: 5,
          name: '抖音外卖点单',
          desc: '通过“抖音”门店账号可查看订单的流水号'
        }
      ],
      serviceTypeDialogShow: false,
      serviceType: 1,
      dyOrderNum: '',
      addressInfo: ''
    }
  },
  created() {
    this.storeId = this.$route.query.storeId
    this.userId = this.$route.query.userId
    this.tableId = this.$route.query.tableId
    if (this.$route.query.tableName) {
      this.tableName = decodeURI(this.$route.query.tableName)
    }
    if (this.$route.query.entranceType) {
      this.entranceType = parseInt(this.$route.query.entranceType)
    }
    if (!this.storeId || !this.entranceType) {
      this.$linkToError('页面失效，请重新扫码点餐')
      return false
    }
    // 手动更新isPad（为了调试）
    if (this.$route.query.isPad) {
      this.updateIsPadVuex(true)
    }

    this.getStoreInfo()

    // 检查点餐环境
    this.$checkEntranceType(this.entranceType)
  },
  computed: {
    ...mapState('order', ['isPad'])
  },
  methods: {
    ...mapActions('order', ['initBaseInfoVuex', 'updateCartIdVuex', 'updateIsPadVuex']),
    getStoreInfo() {
      getStoreInfoApi(this.storeId).then((res) => {
        const { status, data } = res
        if (status !== 0 || !data) {
          return false
        }
        this.storeInfo = data
        this.kdsModel = data.kdsModel || '1'
        this.payModel = data.payModel || 1

        this.initBaseInfo()

        // 仅挂账不需要选人数
        if (this.payModel === 3) {
          this.checkNumItem(1)
          return false
        }
        // 跳转链接中携带了 count 就餐人数 则直接跳转至 Menu 菜谱页面
        if (this.$route.query.count && parseInt(this.$route.query.count)) {
          this.checkNumItem(parseInt(this.$route.query.count))
          return false
        }
        this.loading = false

        // todo: 这里需要接口加一个字段，告诉前端，门店有没有抖音外卖渠道
        if (data.douYinTakeOutOpen === 1) {
          this.serviceTypeDialogShow = true
        }
      })
    },
    initBaseInfo() {
      const sessionData = {
        storeId: this.storeId,
        tableId: this.tableId,
        userId: this.userId,
        entranceType: this.entranceType,
        kdsModel: this.kdsModel,
        payModel: this.payModel
      }
      this.initBaseInfoVuex(sessionData)
    },
    checkSeviceType(code) {
      this.serviceType = code
    },
    confirmSelectServiceType() {
      if (this.serviceType === 5) {
        if (!this.dyOrderNum) {
          this.$toast('请输入流水号')
          return false
        }
        this.checkNumItem(1)
        return false
      }
      this.serviceTypeDialogShow = false
    },
    // 点击更多人数
    handleMoreNumber() {
      const { selectedNum, moreNumberShow } = this
      if (moreNumberShow) {
        // 点击确定跳转菜单页
        if (!selectedNum) {
          return this.$toast('请输入就餐人数')
        }
        this.checkNumItem(selectedNum)
      } else {
        this.moreNumberShow = true
      }
    },
    transferImage(key, type) {
      if (!key && type === 'logo') {
        return require('@/assets/images/logo.png')
      }
      return process.env.VUE_APP_QNY_LOAD_URL + key
    },
    checkNumItem(num) {
      this.$toast.loading({
        duration: 0,
        message: '加载中',
        forbidClick: true
      })
      this.selectedNum = num
      const requestData = {
        storeId: this.storeId,
        tableId: this.tableId,
        entranceType: this.entranceType,
        userId: this.userId,
        guestCount: this.selectedNum,
        serviceType: this.serviceType,
        orderNum: this.dyOrderNum
      }
      goToMenuApi(requestData).then((res) => {
        if (res.status !== 0) {
          // this.$toast.clear()
          // this.$linkToError(res.msg)
          this.$toast.clear()
          this.$toast(res.msg)
          this.dyOrderNum = ''
          this.selectedNum = ''
          this.serviceTypeDialogShow = true
          return false
        }
        this.updateCartIdVuex(res.data)

        // 跳到菜谱页面
        const menuRouterName = this.isPad ? 'OrderPadMenu' : 'OrderMenu'
        this.$router.push({
          name: menuRouterName,
          query: {
            isInit: 1
          }
        })
        this.$toast.clear()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $color-white;
}
.launch-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  .launch-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 15;
  }
  .launch-footer {
    position: fixed;
    bottom: 108px;
    left: 0;
    width: 750px;
    padding: 0 60px;
    text-align: center;
    border-radius: 0;
    z-index: 2000;
    .launch-footer-btn {
      position: relative;
      display: flex;
      width: 100%;
      height: 88px;
      line-height: 86px;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
      color: $color-white;
      background: $color-primary;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
      border-radius: 44px;
    }
  }
}
.brand-logo {
  position: fixed;
  top: 170px;
  left: 375px;
  transform: translateX(-50%);
  width: 150px;
  border-radius: 8px;
}
.number-box {
  position: fixed;
  bottom: 80px;
  left: 375px;
  transform: translateX(-50%);
  width: 606px;
  padding: 24px 57px 40px;
  border-radius: 8px;
  background-color: $color-white;
  z-index: 15;
  .number-header {
    text-align: center;
    &_text_name {
      margin-bottom: 20px;
      font-size: 24px;
      color: $color-text-normal;
    }
    &_text_tips {
      font-size: 30px;
      color: $color-text-main;
    }
  }
  .number-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .number-item_btn {
    display: block;
    width: 148px;
    height: 96px;
    margin-bottom: 24px;
    line-height: 96px;
    color: $color-text-main;
    background-color: rgba($color-primary-bg, 0.3);
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    border-radius: 8px;

    &.active {
      color: $color-white;
      background-color: $color-primary;
    }
  }
  .number-more {
    display: flex;
    &_input {
      flex: 1;
      height: 96px;
      padding: 0 24px;
      font-size: 30px;
      border-radius: 8px;
      border: 2px solid $color-primary;
    }
    .number-item_btn {
      width: 100%;
      font-weight: 400;
      &.active {
        width: 148px;
        margin-left: 24px;
        font-weight: bold;
      }
    }
  }
}

.service-box {
  width: 100%;
  padding-bottom: 24px;
  .service-item {
    width: 100%;
    padding: 24px;
    .item-row {
      display: flex;
      align-items: center;
      &.row-desc {
        margin-top: 8px;
        padding-left: 56px;
        font-size: 24px;
        color: $color-text-sub;
      }
    }
    .service-item-left {
      flex: 0;
      margin-right: 16px;
      font-size: 0;
      svg {
        width: 40px;
        height: 40px;
      }
    }
    .service-item-center {
      flex: auto;
      line-height: 36px;
      text-align: left;
      font-size: 30px;
      color: $color-text-main;
    }
    .service-item-right {
      flex: 0;
      .row_input {
        width: 200px;
        margin-left: 16px;
        padding: 8px 16px;
        font-size: 26px;
        border: 1px solid $color-border;
        border-radius: 8px;
      }
    }
  }
}
</style>
